@import "_bootstrap.scss";
@import "fontawesome/font-awesome";
@import "widgets";

$btn-font-weight: 300;
$font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
    font-weight: 300;
    padding-bottom:50px;
}

#logo-bar{
    margin-top:$margin-md;
    margin-bottom:$margin-md;
    #logo-placeholder > a:first-child{
        display:block;
        background-image:url('../images/logo_alpha.png');
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: contain;
        width:300px;
        height: 81px;
    }

    #opened-status{
        margin-top: 20px;
        text-align: center;
        float:right;
        strong{
            color:$brand-success;
        }
    }
}

.form-group label{
    padding-bottom:0;
}

#menu-bar{
    background-color:$brand-primary;
    .navbar{
        margin:0;
        border-radius:0;
        border:0;
        background-color: $brand-primary;
        .navbar-nav {
            a, a:hover, a:active, a:focus{
                color:#fff;
            }
            > .active{
                a, a:hover, a:active, a:focus{
                    color:$brand-primary;
                    background-color:#fff;
                }
            }
            
        }
        
    }
    #main-menu-navbar{
        padding:0;
        .nav > li > a{
            padding-left:30px;
            padding-right:30px;
        }
    }
    .container-fluid{
        padding:0;
    }
}

#home-banner{
    min-height:300px;
    color:#fff;
    background-repeat:no-repeat;
    background-position:center top;
    background-image: url('../images/home-bg.jpg');
    #home-banner-text{
        margin-top:20px;
    }
}

#page-content{
    margin-top:$margin-md;
}

.wc-widget-wrapper{
    margin-bottom:$margin-md;
    h1, h2, h3, h4, h5, h6, p{
        margin-top:0;
        &:last-child{
            margin-bottom:0;
        }
    }
}

.bordered-padding{
    padding:5px;
    border:1px solid $brand-primary;
}

.blue-box{
    background-color:$brand-primary;
    color:#fff;
    border-radius:5px;
    padding:10px;
}

.bordered{
    border:1px solid $brand-primary;
    
}

iframe{
    display: block;
}

#main-menu.collapse.in > .navbar{
    overflow-x: hidden;
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#000; opacity: 0.9; }
#colorbox{outline:0;}
#cboxContent{margin-top:20px;background:#000;}
.cboxIframe{background:#fff;}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxLoadedContent{border:5px solid #000; background:#fff;}
#cboxTitle{position:absolute; top:-20px; left:0; color:#ccc;}
#cboxCurrent{position:absolute; top:-20px; right:0px; color:#ccc;}
#cboxLoadingGraphic{background:url(images/loading.gif) no-repeat center center;}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

#cboxSlideshow{position:absolute; top:-20px; right:90px; color:#fff;}
#cboxPrevious{position:absolute; top:50%; left:5px; margin-top:-32px; background:url(images/controls.png) no-repeat top left; width:28px; height:65px; text-indent:-9999px;}
#cboxPrevious:hover{background-position:bottom left;}
#cboxNext{position:absolute; top:50%; right:5px; margin-top:-32px; background:url(images/controls.png) no-repeat top right; width:28px; height:65px; text-indent:-9999px;}
#cboxNext:hover{background-position:bottom right;}
#cboxClose{position:absolute; top:5px; right:5px; display:block; background:url(images/controls.png) no-repeat top center; width:38px; height:19px; text-indent:-9999px;}
#cboxClose:hover{background-position:bottom center;}

.wc-dw-gallery{
    .image{
        float:left;
        width:24%;
        min-width:130px;
        margin:0 0.5%;
        a{  
            display:block;
            width:100%;
            margin-bottom:10px;
            height: 180px;
            padding:5px;
            border:1px solid $gray-lighter;
            background-repeat: no-repeat;
            background-position: 100% 50%;
            background-size: contain;
        }
    }
}
